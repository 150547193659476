/*
**  Imports
*/

@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700,900&display=swap');
@import 'scss/reset';
@import 'scss/mixins';
@import 'scss/variables';

/*
**  Main
*/

html {
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    padding: 0;

    #root {
        font-family: $mainfont;

        main {
            padding-top: $padding * 5;
        }

        .inner-container {
            width: $inner-container-width;
            margin: 0 auto;

            @media #{$break-tablet} {
                  width: 90%;
            }
        }

        header {
            @include header;

            #logo {
                width: 6em;
                transition: $transition;

                &:hover {
                    @include zoom-hover;
                }

                img {
                    display: block;
                    width: 100%;
                }
            }

            .inner-container {
                @include flex-ws-center;
                padding: $padding 0;

                nav {
                    ul {
                        @include flex-w-center;

                        li {
                            a {
                                color: $base-font-color;
                                padding: $padding * 1.8 $padding;
                                text-decoration: none;
                                border-bottom: 5px solid transparent;
                                transition: $transition;

                                &.current-menu-item {
                                    color: $logo-blue;
                                    border-bottom: 5px solid $logo-blue;
                                    transition: $transition;
                                }

                                &:hover {
                                    color: $logo-blue;
                                    border-bottom: 5px solid $logo-blue;
                                    transition: $transition;
                                }
                            }
                        }
                    }
                }
            }
        }

        .landing {
            background: transparent url('/images/main_bg.jpg') no-repeat center;
            background-size: cover;
            background-attachment: fixed;
            position: relative;

            .black-overlay {
                background-color: $black;
                opacity: 0;
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 10;
            }

            .inner-container {
                @include flex-center;
                height: 90vh;

                .landing-logo {
                    background-color: $whitebg-op;
                    padding: $padding * 5 $padding * 3;
                    box-shadow: $shadow;
                    transition: $transition;
                    position: relative;
                    z-index: 50;

                    &:hover {
                        @include zoom-hover;
                        background-color: $white;
                        cursor: pointer;
                    }
                }
            }
        }

        .about {
            background-color: $template-light;

            .half-content {
                @include flex-w-center;

                @media #{$break-mobile} {
                    display: block;
                }

                div {
                    width: 50%;

                    @media #{$break-mobile} {
                        width: 100%;
                    }

                    &:last-child {
                        text-align: right;

                        @media #{$break-mobile} {
                            text-align: center;
                            margin-top: $padding;
                        }
                    }
                }
            }
        }

        .gallery {
            background-color: $template-dark;

            .react-tabs {
                &__tab-list {
                    padding: 0;
                }
                &__tab {
                    &--selected {
                        border-radius: 0;
                        background-color: $template-light;
                    }
                }
            }
            .img-container {
                @include flex-w-center;
                flex-wrap: wrap;
                width: 100% !important;;

                &__img {
                    width: 30%;
                    background-color: $darken;
                    margin: $padding auto;
                    transition: $transition;

                    &:hover {
                        cursor: pointer;
                        opacity: .75;
                        transition: $transition;
                    }

                    img {
                        width: 100%;
                        display: block;
                        border: 3px solid white;
                        -webkit-box-shadow: 6px 6px 5px 0px rgba(184,184,184,1);
                        -moz-box-shadow: 6px 6px 5px 0px rgba(184,184,184,1);
                        box-shadow: 6px 6px 5px 0px rgba(184,184,184,1);
                    }
                }
            }
        }

        .contact {
            background: transparent url('/images/footer_bg.jpg') no-repeat center;
            background-size: cover;
            background-attachment: fixed;
            position: relative;

            .black-overlay-footer {
                background-color: $black;
                opacity: 0.5;
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 10;
            }

            .inner-container {
                position: relative;
                z-index: 30;
                color: $white;
                text-align: center;

                h2 span {
                    &:before {
                        background: $white;
                    }
                    &:after {
                        background: $white;
                    }
                }

                a {
                    color: $white;
                }
            }
        }

        section {
            @include section;

            h2 {
                font-size: $fs-h2;
                margin: $padding 0;
                text-align: center;

                span {
                    position: relative;
                    padding: 0 $padding;

                    &:before {
                        content: "";
                        display: block;
                        height: 1px;
                        background: $base-font-color;
                        position: absolute;
                        top: 50%;
                        width: 166px;
                        left: -175px;

                        @media #{$break-mobile} {
                            display: none;
                        }
                    }

                    &:after {
                        content: "";
                        display: block;
                        height: 1px;
                        background: $base-font-color;
                        position: absolute;
                        top: 50%;
                        width: 166px;
                        right: -175px;

                        @media #{$break-mobile} {
                            display: none;
                        }
                    }
                }
            }

            ul {
                padding: $padding 0;
            }

            p, li {
                font-size: $fs-p;
                line-height: 1.75em;
            }
        }

        footer {
            padding: $padding * 2 0;
            background-color: $footer-bg;
            color: $white;

            .inner-container {
                text-align: center;
            }
        }
    }
}
