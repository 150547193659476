@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700,900&display=swap);
/*
**  Imports
*/
/*
**  Reset
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  border: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

/*
**  Mixins
*/
/*
**  Variables
*/
/* Content widths */
/* Fonts */
/* Glutters */
/* Font colors */
/* Colors */
/* Font sizes */
/* Shadows */
/* Effect */
/* Breaks */
/*
**  Main
*/
html {
  margin: 0;
  padding: 0; }

body {
  margin: 0;
  padding: 0; }
  body #root {
    font-family: "Rubik", sans-serif; }
    body #root main {
      padding-top: 5em; }
    body #root .inner-container {
      width: 1280px;
      margin: 0 auto; }
      @media only screen and (max-width: 1280px) {
        body #root .inner-container {
          width: 90%; } }
    body #root header {
      display: block;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 90;
      background-color: #fff; }
      body #root header #logo {
        width: 6em;
        -webkit-transition: all 0.25s ease;
        transition: all 0.25s ease; }
        body #root header #logo:hover {
          -webkit-transition: all .25s ease;
          transition: all .25s ease;
          -webkit-transform: scale(1.1);
                  transform: scale(1.1); }
        body #root header #logo img {
          display: block;
          width: 100%; }
      body #root header .inner-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1em 0; }
        body #root header .inner-container nav ul {
          display: flex;
          justify-content: space-between; }
          body #root header .inner-container nav ul li a {
            color: #333;
            padding: 1.8em 1em;
            text-decoration: none;
            border-bottom: 5px solid transparent;
            -webkit-transition: all 0.25s ease;
            transition: all 0.25s ease; }
            body #root header .inner-container nav ul li a.current-menu-item {
              color: #4F758B;
              border-bottom: 5px solid #4F758B;
              -webkit-transition: all 0.25s ease;
              transition: all 0.25s ease; }
            body #root header .inner-container nav ul li a:hover {
              color: #4F758B;
              border-bottom: 5px solid #4F758B;
              -webkit-transition: all 0.25s ease;
              transition: all 0.25s ease; }
    body #root .landing {
      background: transparent url("/images/main_bg.jpg") no-repeat center;
      background-size: cover;
      background-attachment: fixed;
      position: relative; }
      body #root .landing .black-overlay {
        background-color: #000;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10; }
      body #root .landing .inner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90vh; }
        body #root .landing .inner-container .landing-logo {
          background-color: rgba(255, 255, 255, 0.65);
          padding: 5em 3em;
          box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
          -webkit-transition: all 0.25s ease;
          transition: all 0.25s ease;
          position: relative;
          z-index: 50; }
          body #root .landing .inner-container .landing-logo:hover {
            -webkit-transition: all .25s ease;
            transition: all .25s ease;
            -webkit-transform: scale(1.1);
                    transform: scale(1.1);
            background-color: #fff;
            cursor: pointer; }
    body #root .about {
      background-color: #f2efed; }
      body #root .about .half-content {
        display: flex;
        justify-content: space-between; }
        @media only screen and (max-width: 960px) {
          body #root .about .half-content {
            display: block; } }
        body #root .about .half-content div {
          width: 50%; }
          @media only screen and (max-width: 960px) {
            body #root .about .half-content div {
              width: 100%; } }
          body #root .about .half-content div:last-child {
            text-align: right; }
            @media only screen and (max-width: 960px) {
              body #root .about .half-content div:last-child {
                text-align: center;
                margin-top: 1em; } }
    body #root .gallery {
      background-color: #e7e2db; }
      body #root .gallery .react-tabs__tab-list {
        padding: 0; }
      body #root .gallery .react-tabs__tab--selected {
        border-radius: 0;
        background-color: #f2efed; }
      body #root .gallery .img-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100% !important; }
        body #root .gallery .img-container__img {
          width: 30%;
          background-color: #333;
          margin: 1em auto;
          -webkit-transition: all 0.25s ease;
          transition: all 0.25s ease; }
          body #root .gallery .img-container__img:hover {
            cursor: pointer;
            opacity: .75;
            -webkit-transition: all 0.25s ease;
            transition: all 0.25s ease; }
          body #root .gallery .img-container__img img {
            width: 100%;
            display: block;
            border: 3px solid white;
            box-shadow: 6px 6px 5px 0px #b8b8b8; }
    body #root .contact {
      background: transparent url("/images/footer_bg.jpg") no-repeat center;
      background-size: cover;
      background-attachment: fixed;
      position: relative; }
      body #root .contact .black-overlay-footer {
        background-color: #000;
        opacity: 0.5;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10; }
      body #root .contact .inner-container {
        position: relative;
        z-index: 30;
        color: #fff;
        text-align: center; }
        body #root .contact .inner-container h2 span:before {
          background: #fff; }
        body #root .contact .inner-container h2 span:after {
          background: #fff; }
        body #root .contact .inner-container a {
          color: #fff; }
    body #root section {
      padding: 1em 0 4em;
      background-color: #fff; }
      body #root section h2 {
        font-size: 2.5em;
        margin: 1em 0;
        text-align: center; }
        body #root section h2 span {
          position: relative;
          padding: 0 1em; }
          body #root section h2 span:before {
            content: "";
            display: block;
            height: 1px;
            background: #333;
            position: absolute;
            top: 50%;
            width: 166px;
            left: -175px; }
            @media only screen and (max-width: 960px) {
              body #root section h2 span:before {
                display: none; } }
          body #root section h2 span:after {
            content: "";
            display: block;
            height: 1px;
            background: #333;
            position: absolute;
            top: 50%;
            width: 166px;
            right: -175px; }
            @media only screen and (max-width: 960px) {
              body #root section h2 span:after {
                display: none; } }
      body #root section ul {
        padding: 1em 0; }
      body #root section p, body #root section li {
        font-size: 1em;
        line-height: 1.75em; }
    body #root footer {
      padding: 2em 0;
      background-color: #392e31;
      color: #fff; }
      body #root footer .inner-container {
        text-align: center; }

