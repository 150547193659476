/*
**  Variables
*/

/* Content widths */
$inner-container-width: 1280px;

/* Fonts */
$mainfont: 'Rubik', sans-serif;

/* Glutters */
$padding: 1em;
$padding-ls: .5em;
$padding-s: .25em;

/* Font colors */
$base-font-color: #333;

/* Colors */
$logo-brown: #A45A2A;
$logo-blue: #4F758B;
$white: #fff;
$black: #000;
$darken: #333;
$whitebg-op: rgba(255, 255, 255, .65);
$template-light: #f2efed;
$template-dark: #e7e2db;
$footer-bg: #392e31;

/* Font sizes */
$fs-h1: 3em;
$fs-h2: 2.5em;
$fs-h3: 1.5em;
$fs-h4: 1.25em;
$fs-p: 1em;

/* Shadows */
$shadow: 0 2px 20px 0 rgba(0,0,0,0.20);

/* Effect */
$transition: all .25s ease;

/* Breaks */
$break-tablet: "only screen and (max-width : 1280px)";
$break-mobile: "only screen and (max-width : 960px)";
