/*
**  Mixins
*/

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-w-center {
    display: flex;
    justify-content: space-between;
}

@mixin flex-ws-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin header {
    display: block;
    box-shadow: $shadow;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 90;
    background-color: $white;
}

@mixin section {
    padding: $padding 0 $padding * 4;
    background-color: $white
}

@mixin zoom-hover {
    transition: all .25s ease;
    transform: scale(1.1);
}
